<template>
	<div id="login" class="container-sm flex flex-column align-center mx-auto py-60 px-85 background-white rounded shadow-lg" @keydown.enter="login">
		<img class="mb-50" src="@/assets/terminal.svg" alt="" />

		<input type="text" v-model="form.email" class="w-100 mb-20 border p-20 color-grey rounded shadow" placeholder="Teldupostur" @blur="$v.form.email.$touch()" autofocus />

		<div class="error" v-if="form.email && $v.form.email.$dirty && $v.form.email.$invalid">Vinaliga skriva ein teldubústað</div>

		<input type="password" v-model="form.password" class="w-100 mb-20 border p-20 color-grey rounded shadow" placeholder="Loyniorð" @blur="$v.form.password.$touch()" />

		<div class="error" v-if="passwordWrongError">Brúkaranavnið ella loyniorðið er skeivt</div>

		<a href="#" :class="{ disabled: this.$v.form.$invalid }" class="btn hover:background-blue-600" @click.prevent="login">Rita inn</a>

		<a href="#" class="btn btn-white hover:color-grey-800" @click.prevent>Gloymt loyniorð</a>
	</div>
</template>

<script>
import axios from 'axios';
const { required, email, minLength } = require('vuelidate/lib/validators');

export default {
	name: 'Login',

	data() {
		return {
			loading: false,
			passwordWrongError: false,

			form: {
				email: '',
				password: '',
			},
		};
	},

	validations: {
		form: {
			email: {
				required,
				email,
			},

			password: {
				minLength: minLength(3),
				required,
			},
		},
	},

	methods: {
		async login() {
			if (this.$v.form.$invalid) {
				return;
			}

			const loginDto = { email: this.form.email, password: this.form.password };

			this.loading = true;

			return await axios
				.post(`${process.env.VUE_APP_TERMINAL_API_URL}/auth/login`, loginDto)
				.then(async (response) => {
					const { user, accessToken } = response.data;

					localStorage.setItem('user', JSON.stringify(user));
					localStorage.setItem('access_token', accessToken);

					this.$router.push({ name: 'Customers' });
				})
				.catch(() => {
					this.passwordWrongError = true;
					this.form.password = '';
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
};
</script>
